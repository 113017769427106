import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer'; // optional polyfill
import ViewportObserver from 'viewport-observer';

import {PageWrapper} from '../components/templates';
import {LayoutContainer, Column} from '../components/layouts';
import {SingleBreadcrumb} from '../components/common';
import {HorizontalContentBlock, PositionNav} from '../components/organisms';
import LayoutGenerator from '../helpers/layoutGenerator';
import setButtonConfig from '../helpers/setButtonConfig';
import createSlug from '../helpers/createSlug';
import blockStyles from '../styles/block.module.css';

/**
 * @description Page template for 'single stage' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Stages page React element.
 */
const Stages = ({pageContext}) => {
  if (typeof window === 'undefined') {
    // Ensure proper server rendering.
    return null;
  }
  const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; // TODO: Move that to context to pass it as props to other components
  const pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // TODO: Move that to context to pass it as props to other components
  const headerHeight = pageWidth >= RCDL.config.breakpoints['xl'] ? 120 : 67; // TODO: Calculate header height instead of passing fixed number
  let [currentStage, setCurrentStage] = useState('');

  const pageSpeciesName = pageContext.taxonomies.filter(tax => tax.__typename === 'ContentfulSpecies')[0].speciesName;

  const stages = pageContext.stages.map(node => {
    return {
      ...node,
      __typename: 'ContentfulStage', // Add typename to ensure data structure is correct.
      buttonLabel: 'Read more'
    };
  });

  // TODO: Similar funcionality to journey diagram observer - needs moving to separate component.
  // Calucalates 1px container in the middle of page as intersection root.
  const observerMargin = `${Math.floor((pageHeight - 1) / 2)}px`;

  /**
   * @description Handles intersection event byt setting up current stage id.
   * @param {object} event Object with data passed from intersection observer.
   */
  const handleIntersection = event => {
    if (event.isIntersecting && event.target.id !== currentStage) {
      setCurrentStage(event.target.id);
    }
  };

  useEffect(() => {
    setCurrentStage(createSlug(stages[0].title));
  }, []);

  /**
   * @description Renders stages elements wrapped in the obseerver component.
   * @return {array} Array of React Dom elements.
   */
  const renderStages = () => {
    return stages.map((stage, i) => {
      let buttons = [];
      if (stage.sublinks) {
        let label = stage.sublinks[0].taxonomies.filter(item => item.__typename === 'ContentfulLifestage')[0].title;
        buttons.push(
          setButtonConfig({
            typeName: stage.sublinks[0].__typename,
            fields: stage.sublinks[0].fields,
            cardTitle: stage.sublinks[0].cardTitle,
            buttonLabel: label
          })
        );
      }

      buttons.push(
        setButtonConfig({
          typeName: stage.__typename,
          fields: stage.fields,
          cardTitle: stage.cardTitle,
          buttonLabel: stage.sublinks
            ? stage.taxonomies.filter(item => item.__typename === 'ContentfulLifestage')[0].title
            : stage.buttonLabel
        })
      );

      return (
        <ViewportObserver
          key={stage.id}
          onChange={handleIntersection}
          rootMargin={`-${observerMargin} 0px -${observerMargin} 0px`}
          id={createSlug(stage.title)}
        >
          <HorizontalContentBlock
            {...stage}
            typeName={stage.__typename}
            fields={stage.fields}
            image={stage.cardImage}
            title={stage.cardTitle}
            summary={stage.cardSummary}
            buttonsConfig={buttons}
            additionalWrapperClass="rc-padding-bottom--none rc-margin-bottom--xl rc-padding-left--md"
            order="reverted"
            meta={`Stage ${i + 1}/${stages.length}`}
          />
        </ViewportObserver>
      );
    });
  };
  return (
    <PageWrapper pageContext={pageContext}>
      {pageWidth >= RCDL.config.breakpoints['xl'] && (
        <PositionNav
          sections={stages}
          currentStage={currentStage}
          position="bottom"
          bottomOffset={10}
          scrollOffset={headerHeight + 50}
        />
      )}
      <LayoutContainer columns="one" additionalClass="rc-max-width--xl rc-margin-bottom--lg--mobile">
        <Column additionalClass="rc-padding-bottom--none">
          <div
            className="rc-margin-top--xs--desktop rc-margin-top--xl--mobile
             rc-margin-left--lg--desktop"
          >
            <SingleBreadcrumb
              label={`Exploring ${pageSpeciesName} Ownership`}
              link={`/${pageSpeciesName.toLowerCase()}`}
            />
          </div>
        </Column>
      </LayoutContainer>

      <HorizontalContentBlock {...pageContext} />

      <LayoutContainer
        columns="one"
        additionalClass="rc-max-width--lg rc-padding-right--md rc-padding-left--xl
          rc-padding-left--none--mobile rc-padding-right--none--mobile"
      >
        <Column additionalClass="rc-padding-x--none--mobile">{renderStages()}</Column>
      </LayoutContainer>

      {/* This block is here for now, until we figure out how to make side nav sticky */}
      <div className={`rc-bg-colour--brand3 rc-margin-top--lg ${blockStyles.positionRelative}`}>
        <LayoutGenerator layouts={pageContext.layoutContainer} />
      </div>
    </PageWrapper>
  );
};

Stages.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Stages;
